@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(assets/fonts/Gotham-Medium.otf) format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.swiper-pagination-bullet-active {
  background-color: #03c4eb !important;
}

.swiper-pagination {
  bottom: 0px !important;
}

.mySwiper {
  padding: 4px 2px !important;
  padding-bottom: 20px !important;
}

.banner .swiper-pagination {
  bottom: 10px !important;
}
